import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  FadeReveal,
  Image,
  Location,
  SocialMedia,
  Content,
  Map,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.adonishairsalonanddayspa.com/contact/"}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"contact-container"}
          >
            <Grid.Column width={16}>
              <div className={"contact-form-container"}>
                <ContactForm
                  buttonText={"Submit"}
                  centeredHeaders={false}
                  hideOptIn={false}
                  className={""}
                  fluidButton={false}
                  header={
                    <Grid className={"contact-form-header"}>
                      <Grid.Column width={16} textAlign={"center"}>
                        <FadeReveal triggerOnce={true}>
                          <Image
                            src={getComponentFiles({
                              components:
                                fishermanBusinessWebsitePage.components,
                              componentName: "HeroElement",
                              numToSelect: 1,
                            })}
                            background={true}
                            className={"contact-form-header-image"}
                            loadStrategy={"eager"}
                          >
                            <div>
                              <Header
                                as={"h1"}
                                content={getComponentContentNodeContent({
                                  components:
                                    fishermanBusinessWebsitePage.components,
                                  componentId: "contact_header",
                                  defaultValue: "Contact",
                                })}
                              />
                              <Header
                                as={"h3"}
                                content={fishermanBusiness.primaryLocation.name}
                              />
                              <Location
                                address={{
                                  street:
                                    fishermanBusiness.primaryLocation.street,
                                  city: fishermanBusiness.primaryLocation.city,
                                  state:
                                    fishermanBusiness.primaryLocation.state,
                                  zip: fishermanBusiness.primaryLocation
                                    .zipCode,
                                }}
                                businessName={fishermanBusiness.name}
                                showBusinessName={false}
                              />
                              <SocialMedia
                                socialMediaValues={
                                  fishermanBusiness.socialMedia
                                }
                                buttonType={"rect"}
                                groupButtons={false}
                                showLabels={false}
                              />
                            </div>
                          </Image>
                        </FadeReveal>
                        <Content>
                          <Content.Markup
                            isMarkdown={true}
                            textAlign={"center"}
                            content={getComponentContentNodeContent({
                              components:
                                fishermanBusinessWebsitePage.components,
                              componentId: "contact_form_description",
                            })}
                          />
                        </Content>
                      </Grid.Column>
                    </Grid>
                  }
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={false}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                  event={{ category: "Contact", action: "Submit Inquiry" }}
                />
                <div className={"contact-form-footer"}>
                  <div>
                    <Map
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                      businessName={fishermanBusiness.name}
                    />
                  </div>
                  <div>
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        name
        street
        city
        state
        zipCode
        _id
        hours {
          day
          open
          close
          label
        }
      }
      socialMedia {
        link
        type
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
